<template>
  <app-dialog
    :submitting="status === 'pending'"
    :title="$t('invitation_customization.dialog.title')"
    :width="1200"
    @submit="execute"
  >
    <template #footer>
      <div class="flex items-center justify-end">
        <app-button type="submit">
          {{ $t("app.cta.save") }}
        </app-button>
      </div>
    </template>

    <fieldset>
      <legend class="mb-3">
        <span class="block font-semibold">{{
          $t("invitation_customization.dialog.description_field.title")
        }}</span>
        <span class="block text-sm text-subtle">
          {{ $t("invitation_customization.dialog.description_field.subtitle") }}
        </span>
      </legend>

      <app-form-input-textarea
        v-model.trim="query.description"
        hide-label
        label="Welcome message"
        :placeholder="
          $t('invitation_customization.dialog.description_field.placeholder')
        "
        :rows="6"
      />
    </fieldset>

    <template #aside-right>
      <div
        class="relative h-full w-[50vw] max-w-[600px] overflow-auto rounded-r-lg bg-primary-50 p-8"
      >
        <app-navigation-tabs
          v-model="query.email"
          class="mb-4 !block rounded bg-white shadow-lg"
          dark
          :items="tabs"
          tab-classes="!py-2"
        />

        <div
          class="relative overflow-hidden rounded bg-white px-4 pt-4 shadow-lg"
        >
          <app-slide-x-transition mode="out-in">
            <iframe
              :key="query.email"
              class="max-h-[calc(100dvh-170px)] w-full"
              height="700"
              :src="previewUrl"
              width="600"
            />
          </app-slide-x-transition>

          <div
            class="absolute bottom-0 h-12 w-full bg-gradient-to-t from-white from-30%"
          />
        </div>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import type { NavigationTabItem } from "@pollen/ui-library";

import type { SessionListView } from "~/business-areas/session/session.model";

const properties = defineProps<{
  session: SessionListView;
}>();

const query = reactive({
  email: "registration",
  description: properties.session.description ?? "",
});

const { t } = useI18n();
const tabs = computed<NavigationTabItem[]>(() => [
  {
    label: t("invitation_customization.dialog.tabs.registration"),
    value: "registration",
  },
  {
    label: t("invitation_customization.dialog.tabs.d-7"),
    value: "d-7",
  },
  {
    label: t("invitation_customization.dialog.tabs.d-1"),
    value: "d-1",
  },
]);

const { organization } = useCurrentOrganization();

const previewUrl = ref("");

watchDebounced(
  query,
  () => {
    const search = new URLSearchParams({
      email: query.email,
      description: encodeURIComponent(query.description),
    });

    previewUrl.value = `/api/organizations/${organization.value!.id}/sessions/${
      properties.session.id
    }/invitation-customization/preview?${search.toString()}`;
  },
  { debounce: 150, immediate: true, deep: true },
);

const { $emit } = useNuxtApp();
const { execute, status } = useAsyncData(
  `session_invitation_customization_${properties.session.id}`,
  async () => {
    try {
      await $fetch(
        `/api/organizations/${organization.value!.id}/sessions/${
          properties.session.id
        }/invitation-customization`,
        {
          method: "POST",
          body: { description: encodeURIComponent(query.description) },
        },
      );

      showSuccessToastNotification(
        t("invitation_customization.success_message"),
      );

      $emit("session_invitation_customization_updated");
      useDialog("SessionInvitationCustomizationDialog").close({
        confirmed: true,
      });
    } catch {
      showErrorToastNotification(t("invitation_customization.error_message"));
    }
  },
  {
    immediate: false,
  },
);
</script>
